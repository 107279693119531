/**
 * TODO: Переписать к чертовой матери. Дублирование кода
 */

import { AnimatePresence, m, useIsomorphicLayoutEffect } from 'framer-motion';
import { easeOutQuart } from '@/utils/easings';
import Button from '@/components/shared/Button';
import { useState, FormEvent, useRef, useEffect } from 'react';
import Input from '@/components/shared/Input';
import SearchSVG from '@/svg/search.svg';
import FilterCheckbox from '@/components/shared/FilterCheckbox';
import { useFiltersState } from '@/atoms/catalog/filters-state';
import { produce } from 'immer';
import { useIsFiltersPopupOpenedValue } from '@/atoms/opened-popups';
import ArrowAllBrandsSVG from '@/svg/arrow-all-brands.svg';
import { useMediaQueryDeviceState } from '@/atoms/media-query-device';
import { useSetAllCollectionsWidthState } from '@/atoms/catalog/all-collections-width';
import { useAllCollectionsShowState } from '@/atoms/catalog/all-collections';

interface Props {
    collections: string[];
}

const AllCollection = ({ collections }: Props) => {
    const [showAllCollections, setShowAllCollections] = useAllCollectionsShowState();
    const [searchedCollections, setSearchedCollections] = useState(collections);
    const [filtersState, setFiltersState] = useFiltersState();
    const allCollections = useRef<HTMLDivElement>(null);
    const setWidth = useSetAllCollectionsWidthState();
    const [device] = useMediaQueryDeviceState();
    const isFiltersOpened = useIsFiltersPopupOpenedValue();
    const [collectionsInputValue, setCollectionsInputValue] = useState('');

    useEffect(() => {
        setSearchedCollections(collections);
    }, [collections]);

    useIsomorphicLayoutEffect(() => {
        const onResize = () => {
            if (!isFiltersOpened) {
                setShowAllCollections(true);
                setTimeout(() => {
                    if (allCollections.current) {
                        setWidth(allCollections.current.offsetWidth);
                        setTimeout(() => {
                            setShowAllCollections(false);
                        }, 1);
                    }
                }, 1);
            }
        };

        window.addEventListener('resize', onResize);
        onResize();

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [isFiltersOpened]);

    return (
        <AnimatePresence>
            {showAllCollections && (
                <m.div
                    className="all-brands js-popup-inner"
                    initial={{
                        x: device === 'desktop' ? '-100%' : '100%',
                        opacity: 0,
                    }}
                    exit={{
                        x: '-100%',
                        opacity: 0,
                    }}
                    animate={{
                        x: 0,
                        opacity: 1,
                    }}
                    transition={{
                        ease: easeOutQuart,
                        duration: 0.6,
                    }}
                    ref={allCollections}
                >
                    <Button
                        geometryVariant="square-bracket"
                        className="all-brands-close close-button"
                        onClick={() => {
                            setShowAllCollections(false);
                        }}
                    >
                        x
                    </Button>
                    <button
                        className="all-brands-button-brand"
                        onClick={() => {
                            setShowAllCollections(false);
                        }}
                    >
                        <div className="all-brands-button-brand__icon">
                            <ArrowAllBrandsSVG />
                        </div>
                        <div className="all-brands-button-brand__text">Коллекции</div>
                    </button>
                    <div className="all-brands-search">
                        <Input
                            label={'поиск коллекции'}
                            icon={<SearchSVG />}
                            variant="white"
                            onInput={(event: FormEvent) => {
                                const eventTarget = event.target as HTMLInputElement;
                                setSearchedCollections(
                                    collections.filter((item) => {
                                        return item.toLowerCase().includes(eventTarget.value.toLowerCase());
                                    }),
                                );
                                setCollectionsInputValue(eventTarget.value);
                            }}
                            value={collectionsInputValue}
                            className="all-brands-search__input"
                        />
                    </div>
                    <div className="all-brands-list all-Bracelets-list">
                        {searchedCollections.map((collection, i) => {
                            return (
                                collection && (
                                    <FilterCheckbox
                                        key={i}
                                        name={collection}
                                        value={collection}
                                        checked={
                                            !!filtersState['collection[]'] &&
                                            filtersState['collection[]'].length > 0 &&
                                            !!filtersState['collection[]'].find((item) => item === collection)
                                        }
                                        onChange={(arg) => {
                                            setFiltersState(
                                                produce((draft) => {
                                                    if (arg) {
                                                        draft['collection[]']?.push(collection);
                                                    } else {
                                                        draft['collection[]'] = draft['collection[]']?.filter(
                                                            (item) => item !== collection,
                                                        );
                                                    }
                                                }),
                                            );
                                        }}
                                    >
                                        {collection}
                                    </FilterCheckbox>
                                )
                            );
                        })}
                    </div>
                </m.div>
            )}
        </AnimatePresence>
    );
};

export default AllCollection;
