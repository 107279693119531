import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const allOptionsWidthState = atom<number>({
    key: 'allOptionsWidthState',
    default: 0,
});

export const useAllOptionsWidthValue = () => useRecoilValue(allOptionsWidthState);

export const useSetAllOptionsWidthState = () => useSetRecoilState(allOptionsWidthState);
