import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const allTypesWidthState = atom<number>({
    key: 'allTypesWidthState',
    default: 0,
});

export const useAllTypesWidthValue = () => useRecoilValue(allTypesWidthState);

export const useSetAllTypesWidthState = () => useSetRecoilState(allTypesWidthState);
