import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const allColorsWidthState = atom<number>({
    key: 'allColorsWidthState',
    default: 0,
});

export const useAllColorsWidthValue = () => useRecoilValue(allColorsWidthState);

export const useSetAllColorsWidthState = () => useSetRecoilState(allColorsWidthState);
