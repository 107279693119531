import { useQuery } from '@tanstack/react-query';
import { axiosAPI } from './axios-instance';
import { ApiResponse, Product } from '@/types';

export type ApiProductsRequest = Partial<{
    id?: string;
    limit: number;
    page: number;
    category: number;
    search: string;
    popularity: 1 | 0;
    'brands[]': number[];
    priceMin: number;
    priceMax: number;
    powerMin: number;
    powerMax: number;
    diameterMin: number;
    diameterMax: number;
    available: 1 | 0;
    'materials[]': number[];
    'diameters[]': string[]; // формат `${value} мм`
    'bracelet[]': string[];
    'mechanism[]': string[];
    'options[]': number[];
    'state[]': number[];
    order: string;
    sort: string;
    'type[]': string[];
    'color[]': string[];
    'insert[]': string[];
    'collection[]': string[];
    'dial_color[]': string[];
    'genders[]': number[];
    ids?: number[];
    show_characteristics?: 1 | 0;
}>;

export type ApiProductsResponse = ApiResponse<{
    totalCount: number;
    items: Product[];
    filter?: {
        priceMin: number;
        priceMax: number;
        diameterMin?: number;
        diameterMax?: number;
        type?: string[];
        color?: string[];
        insert?: string[];
        collection?: string[];
    };
}>;

export const getProducts = ({ params, signal }: { params?: ApiProductsRequest; signal?: AbortSignal } = {}) =>
    axiosAPI.get<ApiProductsResponse>(`/v4/products`, { params, signal }).then((res) => res.data);

export const useProductsQuery = ({
    queryParams,
    initialData,
    callback,
    enabled,
}: {
    queryParams?: ApiProductsRequest;
    initialData?: ApiProductsResponse;
    callback?: () => void;
    enabled?: boolean;
} = {}) =>
    useQuery({
        queryKey: ['products', queryParams],
        queryFn: ({ signal }) => getProducts({ params: queryParams, signal }),
        keepPreviousData: true,
        initialData,
        placeholderData: initialData,
        onSettled: () => {
            callback?.();
        },
        enabled,
    });
