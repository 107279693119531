import { Brand } from '@/api/brands';
import { Material } from '@/api/materials';
import Button from '@/components/shared/Button';
import Popup from '@/components/shared/Popup';
import { useIsomorphicLayoutEffect, m } from 'framer-motion';
import { CSSProperties, useState } from 'react';
import AllBrands from './AllBrands';
import { useAllBrandsShowState } from '@/atoms/catalog/all-brands';
import { useIsFiltersPopupOpenedValue, useSetOpenedPopupsState } from '@/atoms/opened-popups';
import RangeSlider from '@/components/shared/RangeSlider/RangeSlider';
import FilterCheckbox from '@/components/shared/FilterCheckbox';
import { Filters, useFiltersState } from '@/atoms/catalog/filters-state';
import useDebouncedFunction from '@/hooks/use-debounced-function';
import { produce } from 'immer';
import ArrowMoreSVG from '@/svg/arrow-more.svg';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { removeNullishAndUndefinedFromObject } from '@/utils/objects/index';
import { useAllBrandsWidthValue } from '@/atoms/catalog/all-brands-width';
import { useAllMaterialsShowState } from '@/atoms/catalog/all-materials';
import AllMaterials from './AllMaterials';
import { useDefaultPricesValue } from '@/atoms/catalog/filter-default-prices-state';
import { useProductTypeValue } from '@/atoms/catalog/filter-product-type-state';
import { useProductInsertValue } from '@/atoms/catalog/filter-product-insert-state';
import { useProductColorValue } from '@/atoms/catalog/filter-product-color-state';
import { useDefaultDiametersValue } from '@/atoms/catalog/filter-default-diameter-state';
import { useAllMaterialsWidthValue } from '@/atoms/catalog/all-materials-width';
import { useDefaultPowersValue } from '@/atoms/catalog/filter-default-powers-state';
import { PresetFilterItem, ProductState } from '@/types';
import { useProductCollectionValue } from '@/atoms/catalog/filter-product-collection-state';
import { useProductDialColorsValue } from '@/atoms/catalog/filter-product-dial-colors-state';
import { FilterFields } from '@/api/productFilters';
import { useAllBraceletsShowState } from '@/atoms/catalog/all-bracelets';
import { useAllBraceletsWidthValue } from '@/atoms/catalog/all-bracelets-width';
import AllBracelets from './AllBracelets';
import { useAllOptionsShowState } from '@/atoms/catalog/all-options';
import { useAllOptionsWidthValue } from '@/atoms/catalog/all-options-width';
import AllOptions from './AllOptions';
import AllMechanisms from './AllMechanisms';
import { useAllMechanismsShowState } from '@/atoms/catalog/all-mechanisms';
import { useAllDialColorsShowState } from '@/atoms/catalog/all-dial-colors';
import { useAllDialColorsWidthValue } from '@/atoms/catalog/all-dial-colors-width';
import AllDialColors from './AllDialColors';
import { useAllMechanismsWidthValue } from '@/atoms/catalog/all-mechanisms-width';
import { useProductBraceletsValue } from '@/atoms/catalog/filter-product-bracelets-state';
import { useProductMechanismsValue } from '@/atoms/catalog/filter-product-mechanisms-state';
import { stateDict } from '@/dicts';
import { addUniqueItemToArray } from '@/utils/arrays/add-unique-item-to-array';
import Dropdown from '@/components/shared/Dropdown';
import { useMediaQuery } from 'usehooks-ts';
import { useAllCollectionsWidthValue } from '@/atoms/catalog/all-collections-width';
import { useAllCollectionsShowState } from '@/atoms/catalog/all-collections';
import AllCollection from './AllCollections';
import { useAllColorsShowState } from '@/atoms/catalog/all-colors';
import { useAllColorsWidthValue } from '@/atoms/catalog/all-colors-width';
import AllColors from './AllColors';
import { useAllInsertsShowState } from '@/atoms/catalog/all-inserts';
import { useAllInsertsWidthValue } from '@/atoms/catalog/all-inserts-width';
import AllInserts from './AllInserts';
import AllTypes from './AllTypes';
import { useAllTypesShowState } from '@/atoms/catalog/all-types';
import { useAllTypesWidthValue } from '@/atoms/catalog/all-types-width';

interface Props {
    brands: Brand[];
    materials: Material[];
    filters: Partial<FilterFields>;
    initialQueryState: Record<string, any>;
    presetFilters: PresetFilterItem[];
    filter: Partial<FilterFields>;
}

export const FILTERS_POPUP_NAME = 'catalog-filters';
const filtersToShowLength = 13;

const clearFilters = (obj: Filters) => {
    const newObj = { ...obj };

    for (const key in newObj) {
        const _key = key as keyof Filters;

        if (Array.isArray(newObj[_key])) {
            newObj[
                _key as 'brands[]' | 'materials[]' | 'type[]' | 'color[]' | 'insert[]' | 'collection[]' | 'dial_color[]'
            ] = [];
        } else {
            newObj[_key] = undefined;
        }
        if (_key === 'category') {
            newObj['category'] = obj['category'];
        }

        if (_key === 'genders[]') {
            newObj['genders[]'] = [];
        }
    }

    document.dispatchEvent(new Event('clear-filters'));

    return newObj;
};

const FiltersPopup = ({ brands, materials, filters, initialQueryState, presetFilters }: Props) => {
    const { closePopup } = useSetOpenedPopupsState();
    const isOpened = useIsFiltersPopupOpenedValue();
    const [namesWidth, setNamesWidth] = useState(0);
    const [showAllBrands, setShowAllBrands] = useAllBrandsShowState();
    const [showAllMaterials, setShowAllMaterials] = useAllMaterialsShowState();
    const [showAllBracelets, setShowAllBracelets] = useAllBraceletsShowState();
    const [showAllOptions, setShowAllOptions] = useAllOptionsShowState();
    const [showAllMechanisms, setShowAllMechanisms] = useAllMechanismsShowState();
    const [showAllDialColors, setShowAllDialColors] = useAllDialColorsShowState();
    const [showAllCollections, setShowAllCollections] = useAllCollectionsShowState();
    const [showAllColors, setShowAllColors] = useAllColorsShowState();
    const [showAllInserts, setShowAllInserts] = useAllInsertsShowState();
    const [showAllTypes, setShowAllTypes] = useAllTypesShowState();
    const [filtersState, setFiltersState] = useFiltersState();
    const router = useRouter();
    const [popupAnimating, setPopupAnimating] = useState(true);
    const materialsWidth = useAllMaterialsWidthValue();
    const brandsWidth = useAllBrandsWidthValue();
    const dialColorsWidth = useAllDialColorsWidthValue();
    const braceletsWidth = useAllBraceletsWidthValue();
    const optionsWidth = useAllOptionsWidthValue();
    const mechanismsWidth = useAllMechanismsWidthValue();
    const collectionsWidth = useAllCollectionsWidthValue();
    const colorsWidth = useAllColorsWidthValue();
    const insertsWidth = useAllInsertsWidthValue();
    const typesWidth = useAllTypesWidthValue();

    const defaultPrice = useDefaultPricesValue();
    const defaultPower = useDefaultPowersValue();
    const defaultDiameters = useDefaultDiametersValue();
    const productTypes = useProductTypeValue();
    const productInserts = useProductInsertValue();
    const productColors = useProductColorValue();
    const productCollection = useProductCollectionValue();
    const productDialColors = useProductDialColorsValue();
    const bracelets = useProductBraceletsValue();
    const mechanisms = useProductMechanismsValue();
    const options = filters.options ?? [];
    const genders = filters.genders ?? [];

    const isLaptop = useMediaQuery('(min-width: 1200px) and (max-width: 1799px)');
    const isTablet = useMediaQuery('(min-width: 1024px) and (max-width: 1199px)');
    const isMobile = useMediaQuery('(max-width: 767px)');

    useIsomorphicLayoutEffect(() => {
        const onResize = () => {
            const names = Array.from(document.querySelectorAll<HTMLElement>('.js-filter-row-name'));
            setNamesWidth(Math.max(...names.map((item) => item.offsetWidth)));
        };

        window.addEventListener('resize', onResize);
        onResize();

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    const updatePrice = useDebouncedFunction((min: number, max: number) => {
        setFiltersState(
            produce((draft) => {
                draft.priceMin = min;
                draft.priceMax = max;
            }),
        );
    }, 100);

    const updateDiameter = useDebouncedFunction((min: number, max: number) => {
        setFiltersState(
            produce((draft) => {
                draft.diameterMin = min;
                draft.diameterMax = max;
            }),
        );
    }, 100);

    const updatePower = useDebouncedFunction((min: number, max: number) => {
        setFiltersState(
            produce((draft) => {
                draft.powerMin = min;
                draft.powerMax = max;
            }),
        );
    }, 100);

    const openDoubleDropdown = (el: HTMLButtonElement) => {
        const firstToggler = el.closest('.js-select-toggler-first');
        const secondToggler = el.closest('.js-select-toggler-second');

        const togglerGroup = el?.closest('[data-dropdown-group]')?.getAttribute('data-dropdown-group');

        if (togglerGroup) {
            if (firstToggler) {
                console.log('1', 1);
                const secondToggler = document.querySelector(
                    `.js-select-toggler-second[data-dropdown-group='${togglerGroup}']`,
                ) as HTMLButtonElement;
                secondToggler.click();
            }

            if (secondToggler) {
                console.log('2', 2);
                const firstToggler = document.querySelector(
                    `.js-select-toggler-first[data-dropdown-group='${togglerGroup}']`,
                ) as HTMLButtonElement;
                firstToggler.click();
            }
        }
    };

    const onScroll = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        let targetElement = event.currentTarget as HTMLButtonElement;

        openDoubleDropdown(targetElement);

        const dropdownMargin = isLaptop ? 120 : isTablet ? 100 : isMobile ? 40 : 140;

        setTimeout(() => {
            const openedDropdown = targetElement.closest('.dropdown--opened');
            const contentEl = targetElement.closest('.js-input-group')?.nextElementSibling;
            const containerRect = document.querySelector('.js-filters-container')?.getBoundingClientRect();
            const dropdownRect = contentEl?.getBoundingClientRect();

            if (containerRect && dropdownRect && openedDropdown) {
                const isDropdownHidden =
                    dropdownRect.bottom > containerRect.bottom - openedDropdown.clientHeight - dropdownMargin;

                if (isDropdownHidden) {
                    openedDropdown.scrollIntoView({
                        block: 'center',
                        behavior: 'smooth',
                    });
                }
            }
        }, 50);
    };

    return (
        <Popup
            name={FILTERS_POPUP_NAME}
            className={classNames('filters-popup', {
                'is-animating': popupAnimating,
                'all-brands--opened': showAllBrands,
                'all-materials--opened': showAllMaterials,
                'all-dial-colors--opened': showAllDialColors,
                'all-bracelets--opened': showAllBracelets,
                'all-options--opened': showAllOptions,
                'all-mechanisms--opened': showAllMechanisms,
                'all-collections--opened': showAllCollections,
                'all-colors--opened': showAllColors,
                'all-inserts--opened': showAllInserts,
                'all-types--opened': showAllTypes,
            })}
            overlay
        >
            <m.div
                className="filters-popup__inner"
                variants={{
                    visible: {
                        transition: {
                            staggerChildren: 0.07,
                            duration: 0.5,
                        },
                        opacity: 1,
                        y: 0,
                    },
                    hidden: {
                        opacity: 0,
                        y: 20,
                    },
                }}
                initial="hidden"
                animate={isOpened ? 'visible' : 'hidden'}
                onAnimationStart={() => {
                    setPopupAnimating(true);
                }}
                onAnimationComplete={() => {
                    setPopupAnimating(false);
                }}
                style={
                    {
                        '--all-brands-width': `${brandsWidth}px`,
                        '--all-materials-width': `${materialsWidth}px`,
                        '--all-dial-colors-width': `${dialColorsWidth}px`,
                        '--all-bracelets-width': `${braceletsWidth}px`,
                        '--all-options-width': `${optionsWidth}px`,
                        '--all-mechanisms-width': `${mechanismsWidth}px`,
                        '--all-collections-width': `${collectionsWidth}px`,
                        '--all-colors-width': `${colorsWidth}px`,
                        '--all-inserts-width': `${insertsWidth}px`,
                        '--all-types-width': `${typesWidth}px`,
                    } as CSSProperties
                }
            >
                <div className="filters-popup__main js-filters-container">
                    <div className="catalog-popup-header">
                        <div className="catalog-popup-title text-xs">Фильтры</div>
                        <Button
                            geometryVariant="square-bracket"
                            onClick={() => {
                                closePopup(FILTERS_POPUP_NAME);
                            }}
                            aria-label="Закрыть фильтры"
                            className="catalog-popup-close close-button"
                        >
                            X
                        </Button>
                    </div>
                    <div
                        className="filters-body"
                        style={namesWidth !== 0 ? ({ '--names-width': `${namesWidth}px` } as CSSProperties) : undefined}
                    >
                        {productTypes && productTypes.length > 0 && (
                            <Dropdown className="select">
                                <div className="input-group js-input-group">
                                    <label className="filter-row__name js-filter-row-name m-text-xs">Тип</label>
                                    <Dropdown.Toggler className="form-control select-toggler" onClick={onScroll}>
                                        <ArrowMoreSVG />
                                    </Dropdown.Toggler>
                                </div>
                                <Dropdown.Content>
                                    <div className="filter-row">
                                        <div className="filter-row__list">
                                            {productTypes.slice(0, filtersToShowLength).map((productType, i) => {
                                                return (
                                                    <FilterCheckbox
                                                        key={i}
                                                        name={productType}
                                                        value={productType}
                                                        onChange={(arg) => {
                                                            setFiltersState(
                                                                produce((draft) => {
                                                                    if (arg) {
                                                                        draft['type[]']?.push(productType);
                                                                    } else {
                                                                        draft['type[]'] = draft['type[]']?.filter(
                                                                            (item: string) => item !== productType,
                                                                        );
                                                                    }
                                                                }),
                                                            );
                                                        }}
                                                        checked={
                                                            !!filtersState['type[]'] &&
                                                            filtersState['type[]'].length > 0 &&
                                                            !!filtersState['type[]'].find(
                                                                (item: string) => item === productType,
                                                            )
                                                        }
                                                    >
                                                        {productType}
                                                    </FilterCheckbox>
                                                );
                                            })}
                                            {productTypes.length > filtersToShowLength && (
                                                <Button
                                                    geometryVariant="mustache"
                                                    className={classNames('all-brands-button m-text-xs', {
                                                        'is-open': showAllTypes,
                                                    })}
                                                    onClick={() => {
                                                        setShowAllTypes((prev) => !prev);
                                                        setShowAllMaterials(false);
                                                        setShowAllMechanisms(false);
                                                        setShowAllBracelets(false);
                                                        setShowAllOptions(false);
                                                        setShowAllDialColors(false);
                                                        setShowAllCollections(false);
                                                        setShowAllColors(false);
                                                        setShowAllInserts(false);
                                                        setShowAllBrands(false);
                                                    }}
                                                >
                                                    Показать ещё
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </Dropdown.Content>
                            </Dropdown>
                        )}

                        {brands.length > 0 && (
                            <Dropdown className="select">
                                <div className="input-group js-input-group">
                                    <label className="filter-row__name js-filter-row-name m-text-xs">Бренд</label>
                                    <Dropdown.Toggler className="form-control select-toggler" onClick={onScroll}>
                                        <ArrowMoreSVG />
                                    </Dropdown.Toggler>
                                </div>
                                <Dropdown.Content>
                                    <div className="filter-row">
                                        <div className="filter-row__list">
                                            {brands.slice(0, filtersToShowLength).map((brand, i) => {
                                                return (
                                                    brand.name && (
                                                        <FilterCheckbox
                                                            key={i}
                                                            name={brand.name}
                                                            value={brand.id.toString()}
                                                            bordered={brand.highlighted}
                                                            checked={
                                                                !!filtersState['brands[]'] &&
                                                                filtersState['brands[]'].length > 0 &&
                                                                !!filtersState['brands[]'].find(
                                                                    (item: number) => item === brand.id,
                                                                )
                                                            }
                                                            onChange={(arg) => {
                                                                setFiltersState(
                                                                    produce((draft) => {
                                                                        if (arg) {
                                                                            draft['brands[]']?.push(brand.id);
                                                                        } else {
                                                                            draft['brands[]'] = draft[
                                                                                'brands[]'
                                                                            ]?.filter(
                                                                                (item: number) => item !== brand.id,
                                                                            );
                                                                        }
                                                                    }),
                                                                );
                                                            }}
                                                        >
                                                            {brand.name}
                                                        </FilterCheckbox>
                                                    )
                                                );
                                            })}
                                            {brands.length > filtersToShowLength && (
                                                <Button
                                                    geometryVariant="mustache"
                                                    className={classNames('all-brands-button m-text-xs', {
                                                        'is-open': showAllBrands,
                                                    })}
                                                    onClick={() => {
                                                        setShowAllBrands((prev) => !prev);
                                                        setShowAllMaterials(false);
                                                        setShowAllMechanisms(false);
                                                        setShowAllBracelets(false);
                                                        setShowAllOptions(false);
                                                        setShowAllDialColors(false);
                                                        setShowAllCollections(false);
                                                        setShowAllColors(false);
                                                        setShowAllInserts(false);
                                                        setShowAllTypes(false);
                                                    }}
                                                >
                                                    Показать ещё
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </Dropdown.Content>
                            </Dropdown>
                        )}

                        {defaultPrice &&
                            defaultPrice.min !== null &&
                            defaultPrice.max !== null &&
                            defaultPrice.min !== defaultPrice.max && (
                                <Dropdown className="select">
                                    <div className="input-group js-input-group">
                                        <label className="filter-row__name js-filter-row-name m-text-xs">Цена</label>
                                        <Dropdown.Toggler
                                            className="form-control select-toggler js-select-toggler-first"
                                            onClick={onScroll}
                                            data-dropdown-group="1"
                                        >
                                            <ArrowMoreSVG />
                                        </Dropdown.Toggler>
                                    </div>
                                    <Dropdown.Content>
                                        <div className="filter-row">
                                            <div className="filter-row__list">
                                                <RangeSlider
                                                    min={defaultPrice.min}
                                                    max={defaultPrice.max}
                                                    currentMin={filtersState.priceMin}
                                                    currentMax={filtersState.priceMax}
                                                    step={10}
                                                    prefix="$ "
                                                    onChange={updatePrice}
                                                />
                                            </div>
                                        </div>
                                    </Dropdown.Content>
                                </Dropdown>
                            )}

                        {defaultPrice &&
                            defaultPrice.min !== null &&
                            defaultPrice.max !== null &&
                            defaultPrice.min !== defaultPrice.max &&
                            presetFilters.length > 0 && (
                                <Dropdown className="select">
                                    <div className="input-group js-input-group">
                                        <label className="filter-row__name js-filter-row-name m-text-xs">
                                            Популярные цены
                                        </label>
                                        <Dropdown.Toggler
                                            className="form-control select-toggler js-select-toggler-second"
                                            onClick={onScroll}
                                            data-dropdown-group="1"
                                        >
                                            <ArrowMoreSVG />
                                        </Dropdown.Toggler>
                                    </div>
                                    <Dropdown.Content>
                                        <div className="filter-row">
                                            <div className="filter-row__list">
                                                {presetFilters.map((priceFilter, i) => {
                                                    if (priceFilter.field === 'price') {
                                                        const priceMin = priceFilter.min ?? defaultPrice.min;
                                                        const priceMax = priceFilter.max ?? defaultPrice.max;

                                                        const isChecked =
                                                            filtersState.priceMin === priceMin &&
                                                            filtersState.priceMax === priceMax;

                                                        return (
                                                            priceFilter.name && (
                                                                <FilterCheckbox
                                                                    key={i}
                                                                    name={priceFilter.name}
                                                                    value={i.toString()}
                                                                    checked={isChecked}
                                                                    onChange={(arg) => {
                                                                        setFiltersState(
                                                                            produce((draft) => {
                                                                                if (arg) {
                                                                                    draft.priceMin =
                                                                                        priceMin ?? undefined;
                                                                                    draft.priceMax =
                                                                                        priceMax ?? undefined;
                                                                                } else {
                                                                                    draft.priceMin = undefined;
                                                                                    draft.priceMax = undefined;
                                                                                }
                                                                            }),
                                                                        );
                                                                    }}
                                                                >
                                                                    {priceFilter.name}
                                                                </FilterCheckbox>
                                                            )
                                                        );
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </Dropdown.Content>
                                </Dropdown>
                            )}

                        {defaultPower &&
                            defaultPower.min !== null &&
                            defaultPower.max !== null &&
                            defaultPower.min !== defaultPower.max && (
                                <Dropdown className="select">
                                    <div className="input-group js-input-group">
                                        <label className="filter-row__name js-filter-row-name m-text-xs">
                                            Запас хода
                                        </label>
                                        <Dropdown.Toggler className="form-control select-toggler" onClick={onScroll}>
                                            <ArrowMoreSVG />
                                        </Dropdown.Toggler>
                                    </div>
                                    <Dropdown.Content>
                                        <div className="filter-row">
                                            <div className="filter-row__list">
                                                <RangeSlider
                                                    min={defaultPower.min}
                                                    max={defaultPower.max}
                                                    currentMin={filtersState.priceMin}
                                                    currentMax={filtersState.priceMax}
                                                    step={10}
                                                    suffix=" Ч"
                                                    onChange={updatePower}
                                                />
                                            </div>
                                        </div>
                                    </Dropdown.Content>
                                </Dropdown>
                            )}

                        <Dropdown className="select">
                            <div className="input-group js-input-group">
                                <label className="filter-row__name js-filter-row-name m-text-xs">Наличие</label>
                                <Dropdown.Toggler className="form-control select-toggler" onClick={onScroll}>
                                    <ArrowMoreSVG />
                                </Dropdown.Toggler>
                            </div>
                            <Dropdown.Content>
                                <div className="filter-row">
                                    <div className="filter-row__list">
                                        <FilterCheckbox
                                            name="available"
                                            value="1"
                                            type="radio"
                                            checked={
                                                typeof filtersState.available !== 'undefined' &&
                                                filtersState.available === 1
                                            }
                                            onChange={(arg) => {
                                                setFiltersState(
                                                    produce((draft) => {
                                                        if (arg) {
                                                            draft.available = 1;
                                                        } else {
                                                            draft.available = undefined;
                                                        }
                                                    }),
                                                );
                                            }}
                                        >
                                            в наличии
                                        </FilterCheckbox>
                                        <FilterCheckbox
                                            name="available"
                                            value="0"
                                            type="radio"
                                            checked={
                                                typeof filtersState.available !== 'undefined' &&
                                                filtersState.available === 0
                                            }
                                            onChange={(arg) => {
                                                setFiltersState(
                                                    produce((draft) => {
                                                        if (arg) {
                                                            draft.available = 0;
                                                        } else {
                                                            draft.available = undefined;
                                                        }
                                                    }),
                                                );
                                            }}
                                        >
                                            под заказ
                                        </FilterCheckbox>
                                    </div>
                                </div>
                            </Dropdown.Content>
                        </Dropdown>

                        {(filters.state ?? []).length > 0 && (
                            <Dropdown className="select">
                                <div className="input-group js-input-group">
                                    <label className="filter-row__name js-filter-row-name m-text-xs">состояние</label>
                                    <Dropdown.Toggler className="form-control select-toggler" onClick={onScroll}>
                                        <ArrowMoreSVG />
                                    </Dropdown.Toggler>
                                </div>
                                <Dropdown.Content>
                                    <div className="filter-row">
                                        <div className="filter-row__list">
                                            {(filters.state ?? []).map((state: ProductState) => (
                                                <FilterCheckbox
                                                    key={state}
                                                    name="state"
                                                    value={state.toString()}
                                                    type="checkbox"
                                                    checked={(filtersState['state[]'] || []).includes(state)}
                                                    onChange={(arg) => {
                                                        setFiltersState(
                                                            produce((draft) => {
                                                                if (arg) {
                                                                    draft['state[]'] = addUniqueItemToArray(
                                                                        draft['state[]'] || [],
                                                                        state,
                                                                    );
                                                                } else {
                                                                    draft['state[]'] = (draft['state[]'] || []).filter(
                                                                        (item) => item !== state,
                                                                    );
                                                                }
                                                            }),
                                                        );
                                                    }}
                                                >
                                                    {stateDict[state]}
                                                </FilterCheckbox>
                                            ))}
                                        </div>
                                    </div>
                                </Dropdown.Content>
                            </Dropdown>
                        )}

                        {materials.length > 0 && (
                            <Dropdown className="select">
                                <div className="input-group js-input-group">
                                    <label className="filter-row__name js-filter-row-name m-text-xs">Материал</label>
                                    <Dropdown.Toggler className="form-control select-toggler" onClick={onScroll}>
                                        <ArrowMoreSVG />
                                    </Dropdown.Toggler>
                                </div>
                                <Dropdown.Content>
                                    <div className="filter-row">
                                        <div className="filter-row__list">
                                            {materials.slice(0, filtersToShowLength).map((material, i) => {
                                                return (
                                                    material.name && (
                                                        <FilterCheckbox
                                                            key={i}
                                                            name={material.name}
                                                            value={material.id.toString()}
                                                            checked={
                                                                !!filtersState['materials[]'] &&
                                                                filtersState['materials[]'].length > 0 &&
                                                                !!filtersState['materials[]'].find(
                                                                    (item: number) => item === material.id,
                                                                )
                                                            }
                                                            onChange={(arg) => {
                                                                setFiltersState(
                                                                    produce((draft) => {
                                                                        if (arg) {
                                                                            draft['materials[]']?.push(material.id);
                                                                        } else {
                                                                            draft['materials[]'] = draft[
                                                                                'materials[]'
                                                                            ]?.filter(
                                                                                (item: number) => item !== material.id,
                                                                            );
                                                                        }
                                                                    }),
                                                                );
                                                            }}
                                                        >
                                                            {material.name}
                                                        </FilterCheckbox>
                                                    )
                                                );
                                            })}
                                            {materials.length > filtersToShowLength && (
                                                <Button
                                                    geometryVariant="mustache"
                                                    className={classNames('all-brands-button m-text-xs', {
                                                        'is-open': showAllMaterials,
                                                    })}
                                                    onClick={() => {
                                                        setShowAllMaterials((prev) => !prev);
                                                        setShowAllBrands(false);
                                                        setShowAllOptions(false);
                                                        setShowAllBracelets(false);
                                                        setShowAllMechanisms(false);
                                                        setShowAllDialColors(false);
                                                        setShowAllCollections(false);
                                                        setShowAllColors(false);
                                                        setShowAllInserts(false);
                                                        setShowAllTypes(false);
                                                    }}
                                                >
                                                    Показать ещё
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </Dropdown.Content>
                            </Dropdown>
                        )}
                        {bracelets.length > 0 && (
                            <Dropdown className="select">
                                <div className="input-group js-input-group">
                                    <label className="filter-row__name js-filter-row-name m-text-xs">Браслет</label>
                                    <Dropdown.Toggler className="form-control select-toggler" onClick={onScroll}>
                                        <ArrowMoreSVG />
                                    </Dropdown.Toggler>
                                </div>
                                <Dropdown.Content>
                                    <div className="filter-row">
                                        <div className="filter-row__list">
                                            {bracelets.slice(0, filtersToShowLength).map((bracelet, i) => {
                                                return (
                                                    <FilterCheckbox
                                                        key={i}
                                                        name={bracelet}
                                                        value={bracelet}
                                                        checked={
                                                            !!filtersState['bracelets[]'] &&
                                                            filtersState['bracelets[]'].length > 0 &&
                                                            !!filtersState['bracelets[]'].find(
                                                                (item: string) => item === bracelet,
                                                            )
                                                        }
                                                        onChange={(arg) => {
                                                            setFiltersState(
                                                                produce((draft) => {
                                                                    if (arg) {
                                                                        draft['bracelets[]']?.push(bracelet);
                                                                    } else {
                                                                        draft['bracelets[]'] = draft[
                                                                            'bracelets[]'
                                                                        ]?.filter((item: string) => item !== bracelet);
                                                                    }
                                                                }),
                                                            );
                                                        }}
                                                    >
                                                        {bracelet}
                                                    </FilterCheckbox>
                                                );
                                            })}
                                            {bracelets.length > filtersToShowLength && (
                                                <Button
                                                    geometryVariant="mustache"
                                                    className={classNames('all-brands-button m-text-xs', {
                                                        'is-open': showAllBracelets,
                                                    })}
                                                    onClick={() => {
                                                        setShowAllBracelets((prev) => !prev);
                                                        setShowAllBrands(false);
                                                        setShowAllMaterials(false);
                                                        setShowAllOptions(false);
                                                        setShowAllMechanisms(false);
                                                        setShowAllCollections(false);
                                                        setShowAllDialColors(false);
                                                        setShowAllColors(false);
                                                        setShowAllInserts(false);
                                                        setShowAllTypes(false);
                                                    }}
                                                >
                                                    Показать ещё
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </Dropdown.Content>
                            </Dropdown>
                        )}
                        {defaultDiameters &&
                            defaultDiameters.min !== null &&
                            defaultDiameters.max !== null &&
                            defaultDiameters.min !== defaultDiameters.max && (
                                <Dropdown className="select">
                                    <div className="input-group js-input-group">
                                        <label className="filter-row__name js-filter-row-name m-text-xs">диаметр</label>
                                        <Dropdown.Toggler
                                            className="form-control select-toggler js-select-toggler-first"
                                            onClick={onScroll}
                                            data-dropdown-group="2"
                                        >
                                            <ArrowMoreSVG />
                                        </Dropdown.Toggler>
                                    </div>
                                    <Dropdown.Content>
                                        <div className="filter-row">
                                            <div className="filter-row__list">
                                                <RangeSlider
                                                    min={defaultDiameters.min}
                                                    max={defaultDiameters.max}
                                                    onChange={updateDiameter}
                                                    step={1}
                                                    suffix=" мм"
                                                />
                                            </div>
                                        </div>
                                    </Dropdown.Content>
                                </Dropdown>
                            )}

                        {defaultDiameters &&
                            defaultDiameters.min !== null &&
                            defaultDiameters.max !== null &&
                            defaultDiameters.min !== defaultDiameters.max &&
                            presetFilters.length > 0 && (
                                <Dropdown className="select">
                                    <div className="input-group js-input-group">
                                        <label className="filter-row__name js-filter-row-name m-text-xs">
                                            Популярные размеры
                                        </label>
                                        <Dropdown.Toggler
                                            className="form-control select-toggler js-select-toggler-second"
                                            onClick={onScroll}
                                            data-dropdown-group="2"
                                        >
                                            <ArrowMoreSVG />
                                        </Dropdown.Toggler>
                                    </div>
                                    <Dropdown.Content>
                                        <div className="filter-row">
                                            <div className="filter-row__list">
                                                {presetFilters.map((sizeFilter, i) => {
                                                    if (sizeFilter.field === 'diameter') {
                                                        const minDiameter = sizeFilter.min ?? defaultDiameters.min;
                                                        const maxDiameter = sizeFilter.max ?? defaultDiameters.max;

                                                        const isChecked =
                                                            filtersState.diameterMin === minDiameter &&
                                                            filtersState.diameterMax === maxDiameter;

                                                        return (
                                                            sizeFilter.name && (
                                                                <FilterCheckbox
                                                                    key={i}
                                                                    name={sizeFilter.name}
                                                                    value={i.toString()}
                                                                    checked={isChecked}
                                                                    onChange={(arg) => {
                                                                        setFiltersState(
                                                                            produce((draft) => {
                                                                                if (arg) {
                                                                                    draft.diameterMin =
                                                                                        minDiameter ?? undefined;
                                                                                    draft.diameterMax =
                                                                                        maxDiameter ?? undefined;
                                                                                } else {
                                                                                    draft.diameterMin = undefined;
                                                                                    draft.diameterMax = undefined;
                                                                                }
                                                                            }),
                                                                        );
                                                                    }}
                                                                >
                                                                    {sizeFilter.name}
                                                                </FilterCheckbox>
                                                            )
                                                        );
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </Dropdown.Content>
                                </Dropdown>
                            )}

                        {productCollection && productCollection.length > 0 && (
                            <Dropdown className="select">
                                <div className="input-group js-input-group">
                                    <label className="filter-row__name js-filter-row-name m-text-xs">Коллекция</label>
                                    <Dropdown.Toggler className="form-control select-toggler" onClick={onScroll}>
                                        <ArrowMoreSVG />
                                    </Dropdown.Toggler>
                                </div>
                                <Dropdown.Content>
                                    <div className="filter-row">
                                        <div className="filter-row__list">
                                            {productCollection.slice(0, filtersToShowLength).map((collection, i) => {
                                                return (
                                                    <FilterCheckbox
                                                        key={i}
                                                        name={collection}
                                                        value={collection}
                                                        onChange={(arg) => {
                                                            setFiltersState(
                                                                produce((draft) => {
                                                                    if (arg) {
                                                                        draft['collection[]']?.push(collection);
                                                                    } else {
                                                                        draft['collection[]'] = draft[
                                                                            'collection[]'
                                                                        ]?.filter(
                                                                            (item: string) => item !== collection,
                                                                        );
                                                                    }
                                                                }),
                                                            );
                                                        }}
                                                        checked={
                                                            !!filtersState['collection[]'] &&
                                                            filtersState['collection[]'].length > 0 &&
                                                            !!filtersState['collection[]'].find(
                                                                (item: string) => item === collection,
                                                            )
                                                        }
                                                    >
                                                        {collection}
                                                    </FilterCheckbox>
                                                );
                                            })}
                                            {productCollection.length > filtersToShowLength && (
                                                <Button
                                                    geometryVariant="mustache"
                                                    className={classNames('all-brands-button m-text-xs', {
                                                        'is-open': showAllCollections,
                                                    })}
                                                    onClick={() => {
                                                        setShowAllCollections((prev) => !prev);
                                                        setShowAllBracelets(false);
                                                        setShowAllMaterials(false);
                                                        setShowAllBrands(false);
                                                        setShowAllMechanisms(false);
                                                        setShowAllDialColors(false);
                                                        setShowAllOptions(false);
                                                        setShowAllColors(false);
                                                        setShowAllInserts(false);
                                                        setShowAllTypes(false);
                                                    }}
                                                >
                                                    Показать ещё
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </Dropdown.Content>
                            </Dropdown>
                        )}

                        {genders && genders.length > 0 && (
                            <Dropdown className="select">
                                <div className="input-group js-input-group">
                                    <label className="filter-row__name js-filter-row-name m-text-xs">Пол</label>
                                    <Dropdown.Toggler className="form-control select-toggler" onClick={onScroll}>
                                        <ArrowMoreSVG />
                                    </Dropdown.Toggler>
                                </div>
                                <Dropdown.Content>
                                    <div className="filter-row">
                                        <div className="filter-row__list">
                                            {genders.map((productGender, i) => {
                                                return (
                                                    <FilterCheckbox
                                                        key={i}
                                                        name={productGender.toString()}
                                                        value={productGender.toString()}
                                                        onChange={(arg) => {
                                                            setFiltersState(
                                                                produce((draft) => {
                                                                    if (arg) {
                                                                        draft['genders[]']?.push(productGender.id);
                                                                    } else {
                                                                        draft['genders[]'] = draft['genders[]']?.filter(
                                                                            (item: number) => item !== productGender.id,
                                                                        );
                                                                    }
                                                                }),
                                                            );
                                                        }}
                                                        checked={
                                                            !!filtersState['genders[]'] &&
                                                            filtersState['genders[]'].length > 0 &&
                                                            (!!filtersState['genders[]'].find(
                                                                (item: number) => item === productGender.id,
                                                            ) ||
                                                                filtersState['genders[]'].find(
                                                                    (item: number) => item === productGender.id,
                                                                ) === 0)
                                                        }
                                                    >
                                                        {productGender.name}
                                                    </FilterCheckbox>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </Dropdown.Content>
                            </Dropdown>
                        )}

                        {options.length > 0 && (
                            <Dropdown className="select">
                                <div className="input-group js-input-group">
                                    <label className="filter-row__name js-filter-row-name m-text-xs">Функции</label>
                                    <Dropdown.Toggler className="form-control select-toggler" onClick={onScroll}>
                                        <ArrowMoreSVG />
                                    </Dropdown.Toggler>
                                </div>
                                <Dropdown.Content>
                                    <div className="filter-row">
                                        <div className="filter-row__list">
                                            {options.slice(0, filtersToShowLength).map((option, i) => {
                                                return (
                                                    <FilterCheckbox
                                                        key={i}
                                                        name={option.name}
                                                        value={option.id.toString()}
                                                        checked={
                                                            !!filtersState['options[]'] &&
                                                            filtersState['options[]'].length > 0 &&
                                                            !!filtersState['options[]'].find(
                                                                (item: number) => item === option.id,
                                                            )
                                                        }
                                                        onChange={(arg) => {
                                                            setFiltersState(
                                                                produce((draft) => {
                                                                    if (arg) {
                                                                        draft['options[]']?.push(option.id);
                                                                    } else {
                                                                        draft['options[]'] = draft['options[]']?.filter(
                                                                            (item: number) => item !== option.id,
                                                                        );
                                                                    }
                                                                }),
                                                            );
                                                        }}
                                                    >
                                                        {option.name}
                                                    </FilterCheckbox>
                                                );
                                            })}
                                            {options.length > filtersToShowLength && (
                                                <Button
                                                    geometryVariant="mustache"
                                                    className={classNames('all-brands-button m-text-xs', {
                                                        'is-open': showAllOptions,
                                                    })}
                                                    onClick={() => {
                                                        setShowAllOptions((prev) => !prev);
                                                        setShowAllBracelets(false);
                                                        setShowAllMaterials(false);
                                                        setShowAllBrands(false);
                                                        setShowAllMechanisms(false);
                                                        setShowAllDialColors(false);
                                                        setShowAllCollections(false);
                                                        setShowAllColors(false);
                                                        setShowAllInserts(false);
                                                        setShowAllTypes(false);
                                                    }}
                                                >
                                                    Показать ещё
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </Dropdown.Content>
                            </Dropdown>
                        )}

                        {mechanisms.length > 0 && (
                            <Dropdown className="select">
                                <div className="input-group js-input-group">
                                    <label className="filter-row__name js-filter-row-name m-text-xs">Механизмы</label>
                                    <Dropdown.Toggler className="form-control select-toggler" onClick={onScroll}>
                                        <ArrowMoreSVG />
                                    </Dropdown.Toggler>
                                </div>
                                <Dropdown.Content>
                                    <div className="filter-row">
                                        <div className="filter-row__list">
                                            {mechanisms.slice(0, filtersToShowLength).map((mechanism, i) => {
                                                return (
                                                    <FilterCheckbox
                                                        key={i}
                                                        name={mechanism}
                                                        value={mechanism}
                                                        checked={
                                                            !!filtersState['mechanisms[]'] &&
                                                            filtersState['mechanisms[]'].length > 0 &&
                                                            !!filtersState['mechanisms[]'].find(
                                                                (item: string) => item === mechanism,
                                                            )
                                                        }
                                                        onChange={(arg) => {
                                                            setFiltersState(
                                                                produce((draft) => {
                                                                    if (arg) {
                                                                        draft['mechanisms[]']?.push(mechanism);
                                                                    } else {
                                                                        draft['mechanisms[]'] = draft[
                                                                            'mechanisms[]'
                                                                        ]?.filter((item: string) => item !== mechanism);
                                                                    }
                                                                }),
                                                            );
                                                        }}
                                                    >
                                                        {mechanism}
                                                    </FilterCheckbox>
                                                );
                                            })}
                                            {mechanisms.length > filtersToShowLength && (
                                                <Button
                                                    geometryVariant="mustache"
                                                    className={classNames('all-brands-button m-text-xs', {
                                                        'is-open': showAllMechanisms,
                                                    })}
                                                    onClick={() => {
                                                        setShowAllMechanisms((prev) => !prev);
                                                        setShowAllBrands(false);
                                                        setShowAllMaterials(false);
                                                        setShowAllOptions(false);
                                                        setShowAllBracelets(false);
                                                        setShowAllColors(false);
                                                        setShowAllDialColors(false);
                                                        setShowAllCollections(false);
                                                        setShowAllInserts(false);
                                                        setShowAllTypes(false);
                                                    }}
                                                >
                                                    Показать ещё
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </Dropdown.Content>
                            </Dropdown>
                        )}

                        {productDialColors && productDialColors.length > 0 && (
                            <Dropdown className="select">
                                <div className="input-group js-input-group">
                                    <label className="filter-row__name js-filter-row-name m-text-xs">
                                        Цвет циферблата
                                    </label>
                                    <Dropdown.Toggler className="form-control select-toggler" onClick={onScroll}>
                                        <ArrowMoreSVG />
                                    </Dropdown.Toggler>
                                </div>
                                <Dropdown.Content>
                                    <div className="filter-row">
                                        <div className="filter-row__list">
                                            {productDialColors.slice(0, filtersToShowLength).map((dialColor, i) => {
                                                return (
                                                    <FilterCheckbox
                                                        key={i}
                                                        name={dialColor}
                                                        value={dialColor}
                                                        onChange={(arg) => {
                                                            setFiltersState(
                                                                produce((draft) => {
                                                                    if (arg) {
                                                                        draft['dial_color[]']?.push(dialColor);
                                                                    } else {
                                                                        draft['dial_color[]'] = draft[
                                                                            'dial_color[]'
                                                                        ]?.filter((item: string) => item !== dialColor);
                                                                    }
                                                                }),
                                                            );
                                                        }}
                                                        checked={
                                                            !!filtersState['dial_color[]'] &&
                                                            filtersState['dial_color[]'].length > 0 &&
                                                            !!filtersState['dial_color[]'].find(
                                                                (item: string) => item === dialColor,
                                                            )
                                                        }
                                                    >
                                                        {dialColor}
                                                    </FilterCheckbox>
                                                );
                                            })}
                                            {productDialColors.length > filtersToShowLength && (
                                                <Button
                                                    geometryVariant="mustache"
                                                    className={classNames('all-brands-button m-text-xs', {
                                                        'is-open': showAllDialColors,
                                                    })}
                                                    onClick={() => {
                                                        setShowAllDialColors((prev) => !prev);
                                                        setShowAllBrands(false);
                                                        setShowAllMechanisms(false);
                                                        setShowAllBracelets(false);
                                                        setShowAllOptions(false);
                                                        setShowAllCollections(false);
                                                        setShowAllMaterials(false);
                                                        setShowAllColors(false);
                                                        setShowAllInserts(false);
                                                        setShowAllTypes(false);
                                                    }}
                                                >
                                                    Показать ещё
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </Dropdown.Content>
                            </Dropdown>
                        )}

                        {productInserts && productInserts.length > 0 && (
                            <Dropdown className="select">
                                <div className="input-group js-input-group">
                                    <label className="filter-row__name js-filter-row-name m-text-xs">Вставка</label>
                                    <Dropdown.Toggler className="form-control select-toggler" onClick={onScroll}>
                                        <ArrowMoreSVG />
                                    </Dropdown.Toggler>
                                </div>
                                <Dropdown.Content>
                                    <div className="filter-row">
                                        <div className="filter-row__list">
                                            {productInserts.slice(0, filtersToShowLength).map((productInsert, i) => {
                                                return (
                                                    <FilterCheckbox
                                                        key={i}
                                                        name={productInsert}
                                                        value={productInsert}
                                                        onChange={(arg) => {
                                                            setFiltersState(
                                                                produce((draft) => {
                                                                    if (arg) {
                                                                        draft['insert[]']?.push(productInsert);
                                                                    } else {
                                                                        draft['insert[]'] = draft['insert[]']?.filter(
                                                                            (item: string) => item !== productInsert,
                                                                        );
                                                                    }
                                                                }),
                                                            );
                                                        }}
                                                        checked={
                                                            !!filtersState['insert[]'] &&
                                                            filtersState['insert[]'].length > 0 &&
                                                            !!filtersState['insert[]'].find(
                                                                (item: string) => item === productInsert,
                                                            )
                                                        }
                                                    >
                                                        {productInsert}
                                                    </FilterCheckbox>
                                                );
                                            })}
                                            {productInserts.length > filtersToShowLength && (
                                                <Button
                                                    geometryVariant="mustache"
                                                    className={classNames('all-brands-button m-text-xs', {
                                                        'is-open': showAllInserts,
                                                    })}
                                                    onClick={() => {
                                                        setShowAllInserts((prev) => !prev);
                                                        setShowAllBrands(false);
                                                        setShowAllMechanisms(false);
                                                        setShowAllBracelets(false);
                                                        setShowAllOptions(false);
                                                        setShowAllCollections(false);
                                                        setShowAllMaterials(false);
                                                        setShowAllDialColors(false);
                                                        setShowAllColors(false);
                                                        setShowAllTypes(false);
                                                    }}
                                                >
                                                    Показать ещё
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </Dropdown.Content>
                            </Dropdown>
                        )}

                        {productColors && productColors.length > 0 && (
                            <Dropdown className="select">
                                <div className="input-group js-input-group">
                                    <label className="filter-row__name js-filter-row-name m-text-xs">Цвет</label>
                                    <Dropdown.Toggler className="form-control select-toggler" onClick={onScroll}>
                                        <ArrowMoreSVG />
                                    </Dropdown.Toggler>
                                </div>
                                <Dropdown.Content>
                                    <div className="filter-row">
                                        <div className="filter-row__list">
                                            {productColors.slice(0, filtersToShowLength).map((productColor, i) => {
                                                return (
                                                    <FilterCheckbox
                                                        key={i}
                                                        name={productColor}
                                                        value={productColor}
                                                        onChange={(arg) => {
                                                            setFiltersState(
                                                                produce((draft) => {
                                                                    if (arg) {
                                                                        draft['color[]']?.push(productColor);
                                                                    } else {
                                                                        draft['color[]'] = draft['color[]']?.filter(
                                                                            (item: string) => item !== productColor,
                                                                        );
                                                                    }
                                                                }),
                                                            );
                                                        }}
                                                        checked={
                                                            !!filtersState['color[]'] &&
                                                            filtersState['color[]'].length > 0 &&
                                                            !!filtersState['color[]'].find(
                                                                (item: string) => item === productColor,
                                                            )
                                                        }
                                                    >
                                                        {productColor}
                                                    </FilterCheckbox>
                                                );
                                            })}
                                            {productColors.length > filtersToShowLength && (
                                                <Button
                                                    geometryVariant="mustache"
                                                    className={classNames('all-brands-button m-text-xs', {
                                                        'is-open': showAllColors,
                                                    })}
                                                    onClick={() => {
                                                        setShowAllColors((prev) => !prev);
                                                        setShowAllBrands(false);
                                                        setShowAllMechanisms(false);
                                                        setShowAllBracelets(false);
                                                        setShowAllOptions(false);
                                                        setShowAllCollections(false);
                                                        setShowAllMaterials(false);
                                                        setShowAllDialColors(false);
                                                        setShowAllInserts(false);
                                                        setShowAllTypes(false);
                                                    }}
                                                >
                                                    Показать ещё
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </Dropdown.Content>
                            </Dropdown>
                        )}
                    </div>
                </div>

                <div className="filters-footer">
                    <Button
                        geometryVariant="mustache"
                        onClick={() => {
                            router.push(
                                {
                                    pathname: router.pathname,
                                    query: {
                                        ...removeNullishAndUndefinedFromObject(clearFilters(router.query)),
                                        page: 1,
                                    },
                                },
                                undefined,
                                { shallow: true },
                            );
                            setFiltersState(clearFilters(filtersState));
                            closePopup(FILTERS_POPUP_NAME);
                        }}
                    >
                        Сбросить
                    </Button>
                    <Button
                        geometryVariant="mustache"
                        onClick={() => {
                            router.push(
                                {
                                    pathname: router.pathname,
                                    query: removeNullishAndUndefinedFromObject({
                                        ...router.query,
                                        ...filtersState,
                                        sort: router.query.sort,
                                        order: router.query.order,
                                        page: 1,
                                    }),
                                },
                                undefined,
                                { shallow: true },
                            );
                            closePopup(FILTERS_POPUP_NAME);
                        }}
                    >
                        Принять
                    </Button>
                </div>

                <AllBrands brands={brands} />
                <AllMaterials materials={materials} />
                <AllDialColors dialColors={productDialColors} />
                <AllBracelets bracelets={bracelets} />
                <AllOptions options={options} />
                <AllMechanisms mechanisms={mechanisms} />
                <AllCollection collections={productCollection} />
                <AllColors colors={productColors} />
                <AllInserts inserts={productInserts} />
                <AllTypes types={productTypes} />
            </m.div>
        </Popup>
    );
};

export default FiltersPopup;
