import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const productMechanisms = atom<string[]>({
    key: 'productMechanismsState',
    default: [],
});

export const useProductMechanismsValue = () => useRecoilValue(productMechanisms);

export const useProductMechanismsState = () => useSetRecoilState(productMechanisms);
