import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const allMechanismsWidthState = atom<number>({
    key: 'allMechanismsWidthState',
    default: 0,
});

export const useAllMechanismsWidthValue = () => useRecoilValue(allMechanismsWidthState);

export const useSetAllMechanismsWidthState = () => useSetRecoilState(allMechanismsWidthState);
