import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const productBracelets = atom<string[]>({
    key: 'productBraceletsState',
    default: [],
});

export const useProductBraceletsValue = () => useRecoilValue(productBracelets);

export const useProductBraceletsState = () => useSetRecoilState(productBracelets);
