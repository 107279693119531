/**
 * TODO: Переписать к чертовой матери. Дублирование кода
 */

import { AnimatePresence, m, useIsomorphicLayoutEffect } from 'framer-motion';
import { easeOutQuart } from '@/utils/easings';
import Button from '@/components/shared/Button';
import { useState, FormEvent, useRef } from 'react';
import Input from '@/components/shared/Input';
import SearchSVG from '@/svg/search.svg';
import FilterCheckbox from '@/components/shared/FilterCheckbox';
import { useFiltersState } from '@/atoms/catalog/filters-state';
import { produce } from 'immer';
import { useIsFiltersPopupOpenedValue } from '@/atoms/opened-popups';
import ArrowAllBrandsSVG from '@/svg/arrow-all-brands.svg';
import { useMediaQueryDeviceState } from '@/atoms/media-query-device';
import { useAllMechanismsShowState } from '@/atoms/catalog/all-mechanisms';
import { useSetAllMechanismsWidthState } from '@/atoms/catalog/all-mechanisms-width';

interface Props {
    mechanisms: string[];
}

const AllMechanisms = ({ mechanisms }: Props) => {
    const [showAllMechanisms, setShowAllMechanisms] = useAllMechanismsShowState();
    const [searchedMechanisms, setSearchedMechanisms] = useState(mechanisms);
    const [filtersState, setFiltersState] = useFiltersState();
    const allMechanisms = useRef<HTMLDivElement>(null);
    const setWidth = useSetAllMechanismsWidthState();
    const [device] = useMediaQueryDeviceState();
    const isFiltersOpened = useIsFiltersPopupOpenedValue();
    const [mechanismsInputValue, setMechanismsInputValue] = useState('');

    useIsomorphicLayoutEffect(() => {
        const onResize = () => {
            if (!isFiltersOpened) {
                setShowAllMechanisms(true);
                setTimeout(() => {
                    if (allMechanisms.current) {
                        setWidth(allMechanisms.current.offsetWidth);
                        setTimeout(() => {
                            setShowAllMechanisms(false);
                        }, 1);
                    }
                }, 1);
            }
        };

        window.addEventListener('resize', onResize);
        onResize();

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [isFiltersOpened]);

    return (
        <AnimatePresence>
            {showAllMechanisms && (
                <m.div
                    className="all-brands js-popup-inner"
                    initial={{
                        x: device === 'desktop' ? '-100%' : '100%',
                        opacity: 0,
                    }}
                    exit={{
                        x: '-100%',
                        opacity: 0,
                    }}
                    animate={{
                        x: 0,
                        opacity: 1,
                    }}
                    transition={{
                        ease: easeOutQuart,
                        duration: 0.6,
                    }}
                    ref={allMechanisms}
                >
                    <Button
                        geometryVariant="square-bracket"
                        className="all-brands-close close-button"
                        onClick={() => {
                            setShowAllMechanisms(false);
                        }}
                    >
                        x
                    </Button>
                    <button
                        className="all-brands-button-brand"
                        onClick={() => {
                            setShowAllMechanisms(false);
                        }}
                    >
                        <div className="all-brands-button-brand__icon">
                            <ArrowAllBrandsSVG />
                        </div>
                        <div className="all-brands-button-brand__text">Механизмы</div>
                    </button>
                    <div className="all-brands-search">
                        <Input
                            label={'поиск механизма'}
                            icon={<SearchSVG />}
                            variant="white"
                            onInput={(event: FormEvent) => {
                                const eventTarget = event.target as HTMLInputElement;
                                setSearchedMechanisms(
                                    mechanisms.filter((item) => {
                                        return item.toLowerCase().includes(eventTarget.value.toLowerCase());
                                    }),
                                );
                                setMechanismsInputValue(eventTarget.value);
                            }}
                            value={mechanismsInputValue}
                            className="all-brands-search__input"
                        />
                    </div>
                    <div className="all-brands-list all-Mechanisms-list">
                        {searchedMechanisms.map((mechanism, i) => {
                            return (
                                mechanism && (
                                    <FilterCheckbox
                                        key={i}
                                        name={mechanism}
                                        value={mechanism}
                                        checked={
                                            !!filtersState['mechanisms[]'] &&
                                            filtersState['mechanisms[]'].length > 0 &&
                                            !!filtersState['mechanisms[]'].find((item) => item === mechanism)
                                        }
                                        onChange={(arg) => {
                                            setFiltersState(
                                                produce((draft) => {
                                                    if (arg) {
                                                        draft['mechanisms[]']?.push(mechanism);
                                                    } else {
                                                        draft['mechanisms[]'] = draft['mechanisms[]']?.filter(
                                                            (item) => item !== mechanism,
                                                        );
                                                    }
                                                }),
                                            );
                                        }}
                                    >
                                        {mechanism}
                                    </FilterCheckbox>
                                )
                            );
                        })}
                    </div>
                </m.div>
            )}
        </AnimatePresence>
    );
};

export default AllMechanisms;
