import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const allBraceletsWidthState = atom<number>({
    key: 'allBraceletsWidthState',
    default: 0,
});

export const useAllBraceletsWidthValue = () => useRecoilValue(allBraceletsWidthState);

export const useSetAllBraceletsWidthState = () => useSetRecoilState(allBraceletsWidthState);
