import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const allInsertsWidthState = atom<number>({
    key: 'allInsertsWidthState',
    default: 0,
});

export const useAllInsertsWidthValue = () => useRecoilValue(allInsertsWidthState);

export const useSetAllInsertsWidthState = () => useSetRecoilState(allInsertsWidthState);
