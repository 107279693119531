import { FormEvent, useEffect, useState } from 'react';
import Button from '../Button';
import TextInput from './TextInput';
import { clamp } from '@/utils/clamp';

interface Props {
    min: number;
    max: number;
    currentMin?: number;
    currentMax?: number;
    prefix?: string;
    suffix?: string;
    step: number;
    onChange?: (min: number, max: number) => void;
}

const RangeSlider = ({ min, max, currentMin, currentMax, prefix, suffix, step, onChange }: Props) => {
    const [minValue, setMinValue] = useState(currentMin || min);
    const [maxValue, setMaxValue] = useState(currentMax || max);

    const handleMinChange = (event: FormEvent) => {
        event.preventDefault();

        const eventTarget = event.target as HTMLInputElement;
        if (eventTarget.value !== '') {
            const newMinVal = Number(eventTarget.value);
            const computedValue = Math.min(newMinVal, maxValue);

            setMinValue(computedValue);
            onChange?.(computedValue, maxValue);
        }
    };

    const handleMaxChange = (event: FormEvent) => {
        event.preventDefault();

        const eventTarget = event.target as HTMLInputElement;

        if (eventTarget.value !== '') {
            const newMaxVal = Number(eventTarget.value);
            const computedValue = Math.max(newMaxVal, minValue);

            setMaxValue(computedValue);
            onChange?.(minValue, computedValue);
        }
    };

    const minPos = ((minValue - min) / (max - min)) * 100;
    const maxPos = ((maxValue - min) / (max - min)) * 100;

    useEffect(() => {
        const onClear = () => {
            setMinValue(min);
            setMaxValue(max);
            onChange?.(min, max);
        };

        if (max < maxValue) {
            setMaxValue(max);
        }

        if (minValue > max) {
            setMinValue(min);
        }

        document.addEventListener('clear-filters', onClear);

        return () => {
            document.removeEventListener('clear-filters', onClear);
        };
    }, [min, max, onChange, maxValue, minValue]);

    return (
        <div className="range-slider-wrapper">
            <div className="range-slider-info">
                <div className="range-slider-info__item m-text-xs">
                    от{' '}
                    <TextInput
                        min={min}
                        max={max}
                        defaultValue={min}
                        value={minValue}
                        prefix={prefix}
                        suffix={suffix}
                        handleChange={(value: number) => {
                            onChange?.(value, maxValue);
                        }}
                        handleBlur={(value: number) => {
                            setMinValue(clamp(Math.min(maxValue, value), min, max));
                        }}
                    />
                    <Button
                        className="range-slider__cross"
                        onClick={() => {
                            setMinValue(min);
                            onChange?.(min, maxValue);
                        }}
                        aria-label="Сбросить минимальную цену"
                    >
                        X
                    </Button>
                </div>
                <div className="range-slider-info__item m-text-xs">
                    до{' '}
                    <TextInput
                        min={min}
                        max={max}
                        defaultValue={max}
                        value={maxValue}
                        prefix={prefix}
                        suffix={suffix}
                        handleChange={(value: number) => {
                            onChange?.(minValue, value);
                        }}
                        handleBlur={(value: number) => {
                            onChange?.(minValue, value);
                            setMaxValue(clamp(Math.max(minValue, value), min, max));
                        }}
                    />{' '}
                    <Button
                        className="range-slider__cross"
                        onClick={() => {
                            setMaxValue(max);
                            onChange?.(minValue, max);
                        }}
                        aria-label="Сбросить максимальную цену"
                    >
                        X
                    </Button>
                </div>
            </div>
            <div className="range-slider-input-wrapper">
                <input
                    className="range-slider-input"
                    type="range"
                    value={minValue}
                    min={min}
                    max={max}
                    step={step}
                    onChange={handleMinChange}
                />
                <input
                    className="range-slider-input"
                    type="range"
                    value={maxValue}
                    min={min}
                    max={max}
                    step={step}
                    onChange={handleMaxChange}
                />
            </div>

            <div className="range-slider-control-wrapper">
                <div className="range-slider-control" style={{ left: `${minPos}%` }} />
                <div className="range-slider-rail">
                    <div
                        className="range-slider-inner-rail"
                        style={{ left: `${minPos}%`, right: `${100 - maxPos}%` }}
                    />
                </div>
                <div className="range-slider-control" style={{ left: `${maxPos}%` }} />
            </div>
        </div>
    );
};

export default RangeSlider;
