/**
 * TODO: Переписать к чертовой матери. Дублирование кода
 */

import { AnimatePresence, m, useIsomorphicLayoutEffect } from 'framer-motion';
import { easeOutQuart } from '@/utils/easings';
import Button from '@/components/shared/Button';
import { useState, FormEvent, useRef, useEffect } from 'react';
import Input from '@/components/shared/Input';
import SearchSVG from '@/svg/search.svg';
import FilterCheckbox from '@/components/shared/FilterCheckbox';
import { useFiltersState } from '@/atoms/catalog/filters-state';
import { produce } from 'immer';
import { useIsFiltersPopupOpenedValue } from '@/atoms/opened-popups';
import ArrowAllBrandsSVG from '@/svg/arrow-all-brands.svg';
import { useMediaQueryDeviceState } from '@/atoms/media-query-device';
import { useAllTypesShowState } from '@/atoms/catalog/all-types';
import { useSetAllTypesWidthState } from '@/atoms/catalog/all-types-width';

interface Props {
    types: string[];
}

const AllTypes = ({ types }: Props) => {
    const [showAllTypes, setShowAllTypes] = useAllTypesShowState();
    const [searchedTypes, setSearchedTypes] = useState(types);
    const [filtersState, setFiltersState] = useFiltersState();
    const allTypes = useRef<HTMLDivElement>(null);
    const setWidth = useSetAllTypesWidthState();
    const [device] = useMediaQueryDeviceState();
    const isFiltersOpened = useIsFiltersPopupOpenedValue();
    const [typesInputValue, setTypesInputValue] = useState('');

    useEffect(() => {
        setSearchedTypes(types);
    }, [types]);

    useIsomorphicLayoutEffect(() => {
        const onResize = () => {
            if (!isFiltersOpened) {
                setShowAllTypes(true);
                setTimeout(() => {
                    if (allTypes.current) {
                        setWidth(allTypes.current.offsetWidth);
                        setTimeout(() => {
                            setShowAllTypes(false);
                        }, 1);
                    }
                }, 1);
            }
        };

        window.addEventListener('resize', onResize);
        onResize();

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [isFiltersOpened]);

    return (
        <AnimatePresence>
            {showAllTypes && (
                <m.div
                    className="all-brands js-popup-inner"
                    initial={{
                        x: device === 'desktop' ? '-100%' : '100%',
                        opacity: 0,
                    }}
                    exit={{
                        x: '-100%',
                        opacity: 0,
                    }}
                    animate={{
                        x: 0,
                        opacity: 1,
                    }}
                    transition={{
                        ease: easeOutQuart,
                        duration: 0.6,
                    }}
                    ref={allTypes}
                >
                    <Button
                        geometryVariant="square-bracket"
                        className="all-brands-close close-button"
                        onClick={() => {
                            setShowAllTypes(false);
                        }}
                    >
                        x
                    </Button>
                    <button
                        className="all-brands-button-brand"
                        onClick={() => {
                            setShowAllTypes(false);
                        }}
                    >
                        <div className="all-brands-button-brand__icon">
                            <ArrowAllBrandsSVG />
                        </div>
                        <div className="all-brands-button-brand__text">Тип</div>
                    </button>
                    <div className="all-brands-search">
                        <Input
                            label={'поиск типа'}
                            icon={<SearchSVG />}
                            variant="white"
                            onInput={(event: FormEvent) => {
                                const eventTarget = event.target as HTMLInputElement;
                                setSearchedTypes(
                                    types.filter((item) => {
                                        return item.toLowerCase().includes(eventTarget.value.toLowerCase());
                                    }),
                                );
                                setTypesInputValue(eventTarget.value);
                            }}
                            value={typesInputValue}
                            className="all-brands-search__input"
                        />
                    </div>
                    <div className="all-brands-list all-Types-list">
                        {searchedTypes.map((bracelet, i) => {
                            return (
                                bracelet && (
                                    <FilterCheckbox
                                        key={i}
                                        name={bracelet}
                                        value={bracelet}
                                        checked={
                                            !!filtersState['type[]'] &&
                                            filtersState['type[]'].length > 0 &&
                                            !!filtersState['type[]'].find((item) => item === bracelet)
                                        }
                                        onChange={(arg) => {
                                            setFiltersState(
                                                produce((draft) => {
                                                    if (arg) {
                                                        draft['type[]']?.push(bracelet);
                                                    } else {
                                                        draft['type[]'] = draft['type[]']?.filter(
                                                            (item) => item !== bracelet,
                                                        );
                                                    }
                                                }),
                                            );
                                        }}
                                    >
                                        {bracelet}
                                    </FilterCheckbox>
                                )
                            );
                        })}
                    </div>
                </m.div>
            )}
        </AnimatePresence>
    );
};

export default AllTypes;
