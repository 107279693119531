import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const allCollectionsWidthState = atom<number>({
    key: 'allCollectionsWidthState',
    default: 0,
});

export const useAllCollectionsWidthValue = () => useRecoilValue(allCollectionsWidthState);

export const useSetAllCollectionsWidthState = () => useSetRecoilState(allCollectionsWidthState);
